"use client";

import PhoneIcon from "@/app/assets/icons/PhoneIcon";
import LanguageChanger from "@/components/LanguageChanger";
import { toggleCallRequestModal } from "@/lib/feature/ui/slice";
import { useAppDispatch } from "@/lib/hooks";
import Image from "next/image";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <div className="fixed top-0 z-50 w-full bg-[#f9fafd]">
      <header className="flex h-[82px] w-full flex-row items-center justify-between px-2 md:px-12">
        <div className="flex items-center">
          {/* logo */}
          <Image
            alt="Logo"
            loading="lazy"
            width="128"
            height="128"
            decoding="async"
            data-nimg="1"
            src="/images/swiss-cover.png"
          />
        </div>
        <div className="flex items-center gap-2">
          <LanguageChanger />
          <button
            className="shrink-0 items-center justify-center whitespace-nowrap rounded-[56px] text-base font-semibold ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-center bg-thirdy text-white hover:bg-thirdy/90 h-[50px] px-[21px] w-fit flex"
            onClick={() => dispatch(toggleCallRequestModal(true))}
          >
            <PhoneIcon />
            <span className="md:inline-flex hidden">{t("Header.key3")}</span>
          </button>
        </div>
      </header>
    </div>
  );
};

export default Header;
